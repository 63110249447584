<template>
  <ModalView :title="editMode ? 'ویرایش کاربر' : 'ایجاد کاربر'" @closed="modalClosed" @opened="modalOpened">

    <VAlert v-if="message" type="error">{{ message }}</VAlert>

    <form @submit.prevent="editMode ? update() : create()">
      <div class="flex mt-4">

        <VInput type="tel" placeholder="شماره موبایل" v-model="user.Mobile" required :disabled="editMode"
          @keypress="isNumber" autocomplete="off" />

        <VButton type="button" class="w-auto mr-2 px-3 py-2" @click="checkExist" v-if="!editMode">
          <SearchIcon class="w-5" />
        </VButton>
      </div>

      <div v-if="editMode || (!editMode && checkedExistence)">

        <div ref="dropzone" class="mt-4 dropzone flex overflow-x-scroll !p-4"
          v-if="!(this.type === 'distributor-manager' && editMode)"></div>

        <VInput type="text" class="mt-4" placeholder="نام" v-model="user.Name" required
          :disabled="this.type === 'distributor-manager' && editMode" />

        <VInput type="text" class="mt-4" placeholder="نام خانوادگی" v-model="user.Family" required
          :disabled="this.type === 'distributor-manager' && editMode" />

        <div v-if="this.type !== 'distributor-manager' || !editMode">
          <VInput type="password" class="mt-4" placeholder="رمز عبور" v-model="user.Password" :required="!editMode"
            autocomplete="new-password" />
          <span class="text-xs text-gray-400" v-if="editMode">* در صورت عدم نیاز به تغییر رمزعبور، فیلد را خالی
            بگذارید</span>
        </div>

        <div v-if="this.type !== 'distributor-manager' || !editMode">
          <VInput type="password" class="mt-4" placeholder="تکرار رمزعبور" v-model="user.PasswordRepeat"
            :required="!editMode" autocomplete="new-password-repeat" />
        </div>

        <Multiselect dir="rtl" class="mt-4" mode="tags" v-model="user.Roles" :options="roles" :searchable="true"
          placeholder="انتخاب نقش" />

        <Multiselect dir="rtl" class="mt-4" mode="tags" v-model="user.Inventories" :options="inventories"
          :searchable="true" placeholder="انتخاب انبار" v-if="this.type === 'admin'" :required="isInventoryRequired" />

        <Multiselect dir="rtl" class="mt-4" v-model="user.VehicleInventoryId" :options="vehicles"
          placeholder="انتخاب ماشین" :searchable="true" v-if="type === 'distributor-manager' && editMode" />

        <div class="mt-4 flex justify-between items-center" v-if="type === 'admin'">
          <span>فعال</span>
          <VSwitch v-model="user.IsActive" :value="user.IsActive" />
        </div>

        <VButton type="submit" class="mt-4" :loading="$loading.value">ثبت</VButton>

      </div>

    </form>
  </ModalView>
</template>

<script>
import ModalView from "@/components/Modal/General/ModalView";
import VInput from "@/components/General/VInput";
import VButton from "@/components/General/VButton";
import Multiselect from '@vueform/multiselect';
import "@vueform/multiselect/themes/default.css";
//import {mapGetters} from "vuex";
import { assignCarToUser, checkUserExist, createUser, getLookupCombos, updateUser } from "@/API/API";
import { SearchIcon } from "@heroicons/vue/outline";
import { Dropzone } from "dropzone";
import VSwitch from "@/components/General/VSwitch.vue";
import VAlert from "@/components/General/VAlert.vue";

const userModel = {
  UserId: '',
  Mobile: '',
  Name: '',
  Family: '',
  Password: '',
  PasswordRepeat: '',
  IsActive: true,
  Roles: [],
  Inventories: [],
  VehicleInventoryId: '',
  Avatar: null
}

export default {
  name: "CreateUserModal",
  props: ['editUser', 'type'],
  emits: ['created', 'updated'],
  components: { VAlert, VSwitch, VButton, VInput, ModalView, Multiselect, SearchIcon },
  data() {
    return {
      user: { ...userModel },
      imageError: false,

      roles: [],
      inventories: [],
      vehicles: [],

      checkedExistence: false,
      message: '',
    }
  },
  computed: {
    //...mapGetters(['selectedInventory']),
    editMode() {
      return this.user.UserId !== ''
    },
    isInventoryRequired() {
      // 1 => admin, 5 => supporter
      return !this.user.Roles.every(role => [1, 5].includes(role))
    },
    subSystemMenuId() {
      switch (this.type) {
        case 'admin':
          return 103010101
        case 'inventory-manager':
          return 103020101
        case 'distributor-manager':
          return 103040201
        default:
          return this.$route.meta.code
      }
    }
  },
  methods: {
    async getData() {
      let data = (await getLookupCombos(this.subSystemMenuId)).data.data

      this.roles = data.Roles?.map(role => ({
        label: role.Name,
        value: role.RoleId
      }))

      this.inventories = data.Inventories?.map(inventory => ({
        label: inventory.Name,
        value: inventory.InventoryId
      }))

      this.vehicles = data.Vehicles?.map(vehicle => ({
        label: vehicle.Name + ` (${vehicle.Plaque})`,
        value: vehicle.VehicleId,
      }))
    },
    checkExist() {
      if (!this.validateMobile())
        return

      checkUserExist(this.user.Mobile, this.subSystemMenuId)
        .then(resp => {
          let user = resp.data.data

          if (user.UserId) {
            this.user = this.$helper.cloneObject(user, Object.keys(userModel))
            this.user.Roles = this.user.Roles.map(role => role.id)
            this.user.Inventories = this.user.Inventories.map(inventory => inventory.InventoryId)
          } else {
            let mobile = this.user.Mobile
            this.user = { ...userModel }
            this.user.Mobile = mobile
          }

          this.checkedExistence = true
          this.initDropzone()
        })
    },
    create() {
      if (this.imageError) {
        this.$errorModal.value = 'عکس خطا دارد، لطفا بررسی کنید'
        return
      }

      if (this.user.Password && (this.user.Password !== this.user.PasswordRepeat)) {
        this.$errorModal.value = 'تکرار رمزعبور صحیح نیست، لطفا مجدد بررسی کنید'
        return
      }

      this.user.Avatar = this.user.Avatar?.JCoFileId
      createUser(this.user, this.subSystemMenuId)
        .then(resp => this.$emit('created', resp.data.data))
    },
    update() {
      if (this.user.Password && (this.user.Password !== this.user.PasswordRepeat)) {
        this.$errorModal.value = 'تکرار رمزعبور صحیح نیست، لطفا مجدد بررسی کنید'
        return
      }
      let hasRole =  (this.user.Roles.length == 0 ? 0 : 1)

      if (this.type === 'distributor-manager')
        assignCarToUser(this.user.UserId, this.user.VehicleInventoryId, hasRole).then(resp => this.$emit('updated', resp.data.data))
      else {
        if (this.imageError) {
          this.$errorModal.value = 'عکس خطا دارد، لطفا بررسی کنید'
          return
        }
        this.user.StaffId = this.user.UserId
        this.user.Avatar = this.user.Avatar?.JCoFileId
        updateUser(this.user.UserId, this.user, this.subSystemMenuId).then(resp => this.$emit('updated', resp.data.data))
      }
    },
    initDropzone() {
      if (this.type === 'distributor-manager' && this.editMode)
        return
      setTimeout(() => {
        let dropzone = new Dropzone(this.$refs.dropzone, {
          url: process.env.VUE_APP_STAFF_URL + 'image',
          headers: { 'Authorization': this.$store.getters.token },
          maxFilesize: 1,
          dictDefaultMessage: 'انتخاب تصاویر',
          dictCancelUpload: 'لغو',
          dictRemoveFile: 'حذف',
          dictMaxFilesExceeded: 'حداکثر یک تصویر میتوانید آپلود کنید.',
          dictFileTooBig: 'حداکثر حجم عکس 1 مگابایت میباشد.',
          acceptedFiles: 'image/jpeg, image/png, image/webp',
          addRemoveLinks: true,
          maxFiles: 1,
          sending(file, xhr, formData) {
            formData.append('type', 'image')
          },
          success: (file, response) => {
            file.JCoFileId = response.JCoFileId;
            this.user.Avatar = {
              JCoFileId: response.JCoFileId,
              Path: response.Path
            }
          },
        });

        // Load Previous Images
        if (this.editUser.Avatar) {
          let mockFile = { name: 'image', size: 0, JCoFileId: this.editUser.Avatar.JCoFileId };
          dropzone.options.addedfile.call(dropzone, mockFile);
          dropzone.options.thumbnail.call(dropzone, mockFile, this.$helper.imageURL(this.editUser.Avatar.Path));
          mockFile.previewElement.classList.add('dz-success');
          mockFile.previewElement.classList.add('dz-complete');
          dropzone.options.maxFiles -= 1;
        }

        // Delete Image
        dropzone.on("removedfile", file => {
          this.imageError = false
          if (file.JCoFileId) {
            this.user.Avatar = null
            dropzone.options.maxFiles += 1
          }
        })

        // Delete Image
        dropzone.on("error", () => this.imageError = true)
      }, 50)
    },
    validateMobile() {
      this.message = ''
      if (this.user.Mobile.length < 11) {
        this.message = 'شماره موبایل باید ۱۱ رقم باشد.'
        return false
      }

      if (!this.user.Mobile.startsWith('09')) {
        this.message = 'شماره موبایل با ۰۹ باید شروع شود.'
        return false
      }

      return true
    },
    isNumber(event) {
      if (event.key === 'Enter') {
        event.preventDefault()
        this.checkExist()
        return
      }

      if (isNaN(parseInt(event.key)) || event.target.value.length === 11)
        event.preventDefault()
    },
    modalOpened() {
      this.getData()
      if (this.editMode)
        this.initDropzone()
    },
    modalClosed() {
      this.checkedExistence = false
      this.message = ''
    }
  },
  watch: {
    editUser(editUser) {
      if (editUser.UserId) {
        this.user = this.$helper.cloneObject(editUser, Object.keys(userModel))
        this.user.Roles = this.editUser.Roles?.map(role => role.RoleId)
        this.user.Inventories = this.editUser.Inventories?.map(inventory => inventory.InventoryId)
        this.user.VehicleInventoryId = this.editUser.DistributorVehicle?.Vehicle?.VehicleId
      } else {
        this.user = { ...userModel }
      }
    },
  }
}
</script>

<style scoped></style>